.menu-item {
  color: black; /* Default text color */
  text-decoration: none; /* Remove underline by default */
  position: relative; /* Position for the pseudo-element */
  transition: color 0.3s; /* Smooth transition for color */
}

.menu-item:hover,
.menu-item:focus,
.selected { /* .selected class is used for active links */
  color: #148b94; /* Hover and active color */
}

/* Pseudo-element for the underline effect */
.menu-item::after {
  content: '';
  display: block;
  height: 2px; /* Height of the underline */
  background-color: #148b94; /* Underline color */
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px; /* Gap between text and underline */
  width: 100%;
  transform: scaleX(0); /* Start with scale 0 (invisible) */
  transition: transform 0.3s ease; /* Transition effect for the underline */
}

.menu-item:hover::after,
.menu-item:focus::after,
.selected::after { /* Updated to use .selected class */
  transform: scaleX(1); /* Scale to 1 (visible) on hover/focus/active */
}

/* Optional: Style for the menu icon */
.menu-icon {
  color: #148b94; /* Same color for the hamburger menu icon */
}
