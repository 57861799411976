.reasons-background {
  background-size: cover;
  background-position: center;
  position: relative;
}

.reasons-background .blur-lg {
  backdrop-filter: blur(20px);
}

.bg-opacity-70 {
  background-color: rgba(0, 0, 0, 0.7);
}
