.offer-section {
  position: relative;
  /* height: 80vh; */
  /* Full viewport height for a hero effect */
  background-image: url('../images/bg_house_2.jpg');
  /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Add a semi-transparent overlay */
.offer-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust transparency */
  z-index: 1;
}

/* Ensuring text content appears above the overlay */
.offer-section>* {
  position: relative;
  z-index: 2;
}
